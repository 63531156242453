body {
    color: #444;
    padding: 0 5% 0;
    font-size: 1.0em
}

header {
    display: block;
    padding-top: 1em;
    padding-bottom: 1em;

    a {
        text-decoration: none;
        font-size: 1.5em;
        color: #444;
    }

    h1 {
        margin-bottom: 0.2em;
    }
}

h1 {
    font-size: 2em;
}

h2 {
    font-size: 1.5em;
}

h3 {
    font-size: 1em;
}

article.post-list {
    section.post-preview {
        margin-bottom: 20px;
        > a {
            color: #444;
            text-decoration: none;
            &:hover {
                color:#0085a1;
            }
        }

        .post-title {
            margin: 0.1em 0;
        }

        .post-meta {
            color: #868e96
        }

        .section-footer {
            font-size: 0.9em;
            color: #777;

            a {
                color: #777;
            }

            time {
                &::before{
                    content: "\A";
                    white-space: pre;
                }
            }
        }
    }
}

pre {
    padding:10px;
}

.pagination {
    display: inline-block;
    margin: 20px 0px;
    a {
        color: #444;
        float: left;
        padding: 8px 16px;
        text-decoration: none;

        &.active {
            color: white;
            font-style: bold;
            background-color: #0085a1;
        }

        &:hover:not(.active) {
            color: white;
            font-style: bold;
            background-color: #ddd;
        }
    }
}

.blog-navigation {
    margin: 30px 0px;
    display: flex;
    justify-content: space-between;
    > a {
        box-sizing: border-box;
    }
}

.related_posts {
    ul {
        list-style-type: none;
        padding-left: 0px;
    }
}

footer.post-footer {
    twitter-share-button {
        display: block;
    }
    time {
        display: block;
        font-size: 0.8em;
        color: #777;
    }
}

footer.page-footer {
    margin: 10px 0px;
    padding: 10px;

    div.profile {
        display: flex;

        > div {
            box-sizing: border-box;
        }
        .profile-name {
            font-weight: bold;
        }
        img.profile-icon {
            float: left;
        }
    }

    div.social-links {
        display: flex;
        .icon {
            box-sizing: border-box;
            img {
                width: 36px;
                height: 36px;
            }
        }
    }

    div.copyright-box {
        text-align: center;
    }
}
